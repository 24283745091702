/* LIGHT COLORS */
$light-text-color: #000;
$light-link-color: #004eff;
$light-background-color: #fff;

$light-header-background-color: lighten($light-text-color, 85%);
$light-nav-background-color: lighten($light-text-color, 94%);
$light-code-background-color: $light-header-background-color;

/* DARK COLORS */
$dark-text-color: #fff;
$dark-link-color: #659aff;
$dark-background-color: #333;

$dark-header-background-color: darken($dark-text-color, 94%);
$dark-nav-background-color: darken($dark-text-color, 85%);
$dark-code-background-color: $dark-header-background-color;

/* SIZES */
$website-size: 700px; // width of content of the website
$website-padding: 25px; // left/right padding of website content

$header-padding: 15px; // top/bottom padding of header
$navigation-padding: 20px; // top/bottom padding of navbar

$navigation-item-spacing: 20px; // spacing between each of the navigation items

$code-padding: 3px; // padding for inline code will be this, and double this for regular code
$code-border-roundness: 6px; // roundness of the code border

$title-size: 180%; // how much bigger is the title
$small-title-size: 155%; // how much bigger is the title
$navigation-size: 120%; // how much bigger is the title
$footer-size: 80%; // how much smaller is the footer
$toc-size: $footer-size; // how much smaller is the TOC

$line-height: 1.4; // increase line height to make text more readable

$line-spacing: 20px; // spacing after paragraph

$h2-size: 36px;
$h3-size: 26px;
$h4-size: 21px;
$h5-size: 15px;
$h6-size: 13px;

$h2-spacing: 8px;
$h3-spacing: 5px;
$h4-spacing: 3px;
$h5-spacing: 2px;
$h6-spacing: 1px;

$list-item-left-padding: 25px; // so bullets don't stick out the page
// sort of a hack, I don't know how else to do this

$small-screen-threshold: 550px; // if the screen is smaller than this, website changes stuff
$tiny-screen-threshold: 375px; // if the screen is smaller than this, website changes stuff

/* FONT SETTINGS */
$serif-font: "Fira Sans Light";
$monospaced-font: "Fira Code Light";
$icons-font: "Icons Font";
